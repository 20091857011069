import {
    all,
    call,
    delay,
    fork,
    put,
    race,
    select,
    take
} from "redux-saga/effects";
import {getApiUrl} from "../../config/helper";
import {getCustomer, getLanguage, getPlayerName, getSliderOrientation} from "../../selectors";
import {
    ADS_RECEIVED,
    BIKES_RECEIVED,
    BUS_RECEIVED,
    EREIDER_RECEIVED,
    EVENTS_TODAY_RECEIVED,
    FETCH_ERROR,
    IMXPOI_RECEIVED,
    JOBS_RECEIVED,
    METEO_RECEIVED,
    NAVIGATION_UPDATE,
    NEWS_RECEIVED,
    PARKING_RECEIVED,
    POI_HELPERKNAPP_RECEIVED,
    POI_RECEIVED,
    POLL_RECEIVED,
    POLL_START,
    POLL_STOP,
    RADAR_RECEIVED,
    RTLNEWS_RECEIVED, RTLSDG_RECEIVED, RTLWEATHER_RECEIVED, SDG_RECEIVED,
    TRAFIC_RECEIVED,
    TRAINS_RECEIVED,
    VIDEOADS_RECEIVED,
    WHATTODO_RECEIVED
} from "../actions";

function* watchFetchNews() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/news?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: NEWS_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchVideoads() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    const sliderOrientation = yield select(getSliderOrientation);
    let apiUrl = getApiUrl(customer);

    let orientation = "landscape";
    if (sliderOrientation) orientation = sliderOrientation;

    try {
        let fetchUrl = `${apiUrl}/videoads?orientation=${orientation}&customer=${customer}&language=${language}`;

        if (customer === "rtl") fetchUrl = "/videoads_livearena.json";
        const json = yield fetch(fetchUrl).then((response) => response.json());
        yield put({ type: VIDEOADS_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchMeteo() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/meteo2?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: METEO_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchEventsToday() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/events?provider=eil&customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: EVENTS_TODAY_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchPoi() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const jsonPoi = yield fetch(
            `${apiUrl}/poi?customer=${customer}&language=${language}`
        ).then((response) => response.json());

        yield put({
            type: POI_RECEIVED,
            json: { ...jsonPoi },
        });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchPoiHelperknapp() {
    const customer = yield select(getCustomer);

    if (customer !== 'helperknapp') return;

    try {
        const jsonPoi = yield fetch(
            'https://sm4rt.online/api/export/forms/4871d790-ca10-4156-a332-b1d8d022d4b6/json'
        ).then((response) => response.json());

        yield put({
            type: POI_HELPERKNAPP_RECEIVED,
            json: jsonPoi ,
        });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchIMXPoi() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const jsonPoi = yield fetch(
            `${apiUrl}/imx?customer=${customer}&language=${language}`,
       //     {
       //         mode: 'cors',
       //         headers: {
       //             "access-control-allow-origin": "*",
       //         }          
       //     },
        ).then((response) => response.json());

        yield put({
            type: IMXPOI_RECEIVED,
            json: { ...jsonPoi },
        });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchWhatToDo() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const jsonPoi = yield fetch(
            `${apiUrl}/whattodo?customer=${customer}&language=${language}`
        ).then((response) => response.json());

        yield put({
            type: WHATTODO_RECEIVED,
            json: { ...jsonPoi },
        });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchEreider() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/raider?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        //yield delay(2000);
        yield put({ type: NAVIGATION_UPDATE, json: json });
        yield put({ type: EREIDER_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchTrafic() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/trafic?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: TRAFIC_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchBikes() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    const playerName = yield select(getPlayerName);
    let apiUrl = getApiUrl(customer);

    let name = '';
    if (playerName)
        name = playerName;

    try {
        const json = yield fetch(
            `${apiUrl}/bikes?customer=${customer}&language=${language}&name=${name}`
        ).then((response) => response.json());
        yield put({ type: BIKES_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchTrains() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/train?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: TRAINS_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchBus() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/bus?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: BUS_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchJobs() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/jobs?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: JOBS_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchPoll() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/voting?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: POLL_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchSDG() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/sdg?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: SDG_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchRadar() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/radar?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: RADAR_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchParking() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    let apiUrl = getApiUrl(customer);

    try {
        const json = yield fetch(
            `${apiUrl}/parking?customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: PARKING_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchAds() {
    const customer = yield select(getCustomer);
    const language = yield select(getLanguage);
    const sliderOrientation = yield select(getSliderOrientation);
    let apiUrl = getApiUrl(customer);

    let orientation = "portrait";
    if (sliderOrientation) orientation = sliderOrientation;

    try {
        const json = yield fetch(
            `${apiUrl}/videoads?orientation=${orientation}&customer=${customer}&language=${language}`
        ).then((response) => response.json());
        yield put({ type: ADS_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchRTLNews() {
    const language = yield select(getLanguage);
    
    let lang = language;
    if (lang === 'de') lang = 'lu';

    let baseUrl = 'https://api-gw.rtl.lu/niche-guardian/v1';

    try {
        const json = yield fetch(
            `${baseUrl}/news?limit=10&lang=${lang}`
        ).then((response) => response.json());
        yield put({ type: RTLNEWS_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchRTLWeather() {
    const language = yield select(getLanguage);
    
    let lang = language;
    if (lang === 'de') lang = 'lu';

    let baseUrl = 'https://api-gw.rtl.lu/niche-guardian/v1';

    try {
        const json = yield fetch(
            `${baseUrl}/meteo`
        ).then((response) => response.json());
        yield put({ type: RTLWEATHER_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* watchFetchRTLSDG() {
    const language = yield select(getLanguage);
    
    let lang = language;
    if (lang === 'de') lang = 'lu';

    let baseUrl = 'https://api-gw.rtl.lu/niche-guardian/v1';

    try {
        const json = yield fetch(
            `${baseUrl}/sdg`
        ).then((response) => response.json());
        yield put({ type: RTLSDG_RECEIVED, json: json });
    } catch (error) {
        yield put({ type: FETCH_ERROR, error });
    }
}

function* fetchData() {
    while (true) {
        try {
            yield all([
                fork(watchFetchNews),
                fork(watchFetchVideoads),
                fork(watchFetchMeteo),
                fork(watchFetchEventsToday),
                fork(watchFetchPoi),
                fork(watchFetchPoiHelperknapp),
                fork(watchFetchIMXPoi),
                fork(watchFetchWhatToDo),
                fork(watchFetchBikes),
                fork(watchFetchTrains),
                fork(watchFetchBus),
                fork(watchFetchTrafic),
                fork(watchFetchJobs),
                fork(watchFetchPoll),
                fork(watchFetchSDG),
                fork(watchFetchRadar),
                fork(watchFetchParking),
                fork(watchFetchAds),
                fork(watchFetchRTLNews),
                fork(watchFetchRTLWeather),
                fork(watchFetchRTLSDG),
                fork(watchFetchEreider),
            ]);
        } catch (e) {
            // TODO: call error action
        }
        yield delay(300000); // MS 300000
    }
}

export default function* fetchDataWatcher() {
    while (true) {
        yield take(POLL_START);
        yield race([call(fetchData), take(POLL_STOP)]);
    }
}
