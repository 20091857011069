import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import LazyLoad from 'react-lazyload';
import {Translate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import GEventMap from '../components/GEventMap';
import {GridHorizontal} from '../components/Layout';
import SwipeIndicator from '../components/SwipeIndicator';
import images from '../config/images';
import {
    SET_ACTIONBUTTON, SET_SELECTED_EVENT,

    SET_SWIPEINDICATOR
} from '../redux/actions';



const HomeWrapper = styled.div`
    position: relative;
    transform: translateZ(0);

    ${props => props.theme.layout !== 3 && `
        height: 100%;
    `}

    ${props => props.theme.layout === 3 && `
        overflow-x: scroll;
    `}
`;

const Event = styled.div`
    flex: 0 0 ${props => props.theme.scale * props.theme.agendaWidth}px;
    width: ${props => props.theme.scale * props.theme.agendaWidth}px;
    height: ${props => props.theme.scale * props.theme.agendaHeight}px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    * {
        box-sizing: border-box;
    }

`;

const EventImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${props => props.theme.black};
    text-align: center;
    flex: 0 0 auto;
`;

const EventImage = styled.img`
    height: ${props => props.theme.scale * 480}px;
    width: 100%;
    object-fit: cover;
`;

const EventContent = styled.div`
    padding: ${props => props.theme.scale * 15}px;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background-color: rgba(255,255,255,0.8);
`;

const EventType = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-300';
    margin-bottom: ${props => props.theme.scale * 3}px;
    text-transform: uppercase;

    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const EventTitle = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-900';
    margin-bottom: ${props => props.theme.scale * 5}px;

    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const EventVenue = styled.div`
    font-size: ${props => props.theme.scale * 16}px;
    font-family: 'MuseoSans-300';
    margin-bottom: ${props => props.theme.scale * 5}px;

    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const EventDate = styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-900';

    ${(props) =>
        props.theme.textHighlight
            && `
            color: ${props.theme.textHighlight};
    `}
`;

const EventHour = styled.span`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-300';

    ${(props) =>
        props.theme.textHighlight
            && `
            color: ${props.theme.textHighlight};
    `}
`;

const Detail = styled.div`
    width: 100%;
    height: 100%;    
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-color: ${props => props.theme.white};
    z-index: 888;
`;

const DetailTop = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${props => props.theme.scale * 40}px;
    height: 100%;
`;

const DetailImageWrapper = styled.div`
    width: 50%;
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.lightgray};
`;

const DetailContent = styled.div`
    width: 50%;
    flex: 0 0 50%;
    box-sizing: border-box;
    padding: ${props => props.theme.scale * 40}px;
    overflow-y: scroll;
`;

const DetailQRCodes = styled.div`
    width: 100%;
    height: ${props => props.theme.scale * 150}px;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    img {
        width: ${props => props.theme.scale * 90}px;
    }

    h4 {
        font-size: ${props => props.theme.scale * 12}px;
        text-transform: uppercase;
        margin: 0;
    }
`;

const TicketQRCode = styled.div`
    flex: 0 1 auto;
    text-align: center;
    padding: 0 0 0 ${props => props.theme.scale * 28}px;
`;

const VenueQRCode = styled.div`    
    flex: 0 1 auto;
    text-align: center;
    padding: 0 0 0 ${props => props.theme.scale * 28}px ;
`;

const DetailType = styled.div`
    font-size: ${props => props.theme.scale * 18}px;
    font-family: 'MuseoSans-300';
    margin-bottom: ${props => props.theme.scale * 3}px;
    text-transform: uppercase;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailTitle = styled.div`
    font-size: ${props => props.theme.scale * 36}px;
    font-family: 'MuseoSans-900';
    margin-bottom: ${props => props.theme.scale * 5}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;
const DetailDate = styled.div`
    font-size: ${props => props.theme.scale * 28}px;
    font-family: 'MuseoSans-300';
    margin-bottom: ${props => props.theme.scale * 20}px;
    ${(props) =>
        props.theme.textHighlight
            && `
            color: ${props.theme.textHighlight};
    `}
`;

const DetailVenue = styled.div`
    box-sizing: border-box;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailVenueName = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-900';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailVenueAddress = styled.div`
    font-size: ${props => props.theme.scale * 22}px;
    font-family: 'MuseoSans-300';
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailDescription= styled.div`
    font-size: ${props => props.theme.scale * 20}px;
    font-family: 'MuseoSans-300';
    box-sizing: border-box;
    margin-top: ${props => props.theme.scale * 40}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const DetailMap= styled.div`
    width: 100%;
    

    ${props => props.theme.layout !== 4 && `
        height: ${props.theme.scale * 430}px;
    `}

    ${props => props.theme.layout === 4 && `
        height: ${props.theme.scale * 1000}px;
    `}
`;

const DetailMapWrapper= styled.div``;

const LazyLoadWrapper = styled(LazyLoad)`
    height: ${props => props.theme.scale * 480}px;
`;

const Close = styled.div`
    cursor: Pointer;
    user-select: none;
    width: ${props => props.theme.scale * 40}px;
    height: ${props => props.theme.scale * 40}px;
    position: absolute;
    right: ${props => props.theme.scale * 20}px;

    ${(props) =>
        props.$mr  &&
        props.theme.layout === 4 ?
        `top: ${props.theme.scale * 1320}px;` : `top: ${props.theme.scale * 22}px;`};
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${props => props.theme.scale * ((props.theme.contentHeight / 2) - 97)}px;
    left: ${props => props.theme.scale * ((props.theme.contentWidth / 2) - 45)}px;
`;

const Placeholder = styled.div`
    ${props => props.theme.layout !== 3 && `
        height: 100%;
    `}
    
    ${props => props.theme.layout === 3 && `
        height: ${props.theme.scale * 960}px;
    `}
`;

const PlaceholderBackground = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Events = () => {
    const selectedEvent = useSelector(state => state.data.selectedEvent);
    const events = useSelector(state => state.data.eventsToday);
    const swipeIndicator = useSelector(state => state.config.swipeIndicator);
    const layout = useSelector(state => state.config.layout);
    const navItem = useSelector(state => state.config.navItem);
    const theme = useSelector(state => state.config.theme);
    const mobiliteReduite = useSelector(
        (state) => state.config.mobiliteReduite
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SET_ACTIONBUTTON, actionButton: "copyright"});
    }, [dispatch]);

    if (!events) return null;

    const eventClicked = (event) => {
        if (navItem.noDetail === true) return false;
        if (theme.eventsNoDetail) return false;

        dispatch({ type: SET_SELECTED_EVENT, event: event});
        if (event.venue.website) {
            dispatch({ type: SET_ACTIONBUTTON, actionButton: "mail", action: event.venue.website});
        } else {
            dispatch({ type: SET_ACTIONBUTTON, actionButton: undefined});
        } 
    }

    const closeClicked = () => { 
        dispatch({ type: SET_SELECTED_EVENT, event: undefined});
    }

    const hideSwiper = () => { 
        if (swipeIndicator === true) dispatch({ type: SET_SWIPEINDICATOR, state: false});
    }

    
    let placeholder = [];
    if (events.length < 9) {
        let num = 8 - events.length;

        for (let index = 0; index < num; index++) {
            placeholder.push(
                <Event key={index}>
                    <EventImageWrapper>
                        <EventImage src={images.placeholder.agenda_big} alt="No Event" />
                    </EventImageWrapper>
                    <EventContent>
                    <EventTitle><Translate id={"events.noevents"} /></EventTitle>
                    </EventContent>
                </Event>
            );
            
        }
    }
    
    
    return (
        <HomeWrapper onMouseDown={hideSwiper} onTouchStart={hideSwiper} onScroll={hideSwiper}>
            {selectedEvent && (
                <Detail>
                    <Close onClick={() => closeClicked()} $mr={mobiliteReduite}><img src={images.icons.close} alt="Close" /></Close>
                    <DetailTop>
                        <DetailImageWrapper>
                            <LazyLoadWrapper>
                            {selectedEvent.imageurl && <EventImage src={selectedEvent.imageurl} alt={selectedEvent.title} />}
                            </LazyLoadWrapper>
                            <DetailMapWrapper>
                                <DetailQRCodes>
                                    {selectedEvent.tickerqrcode && <TicketQRCode><h4>Ticket</h4><img src={selectedEvent.tickerqrcode} alt="QR Code" /></TicketQRCode>}
                                    {selectedEvent.venueqrcode && <VenueQRCode><h4>Itineraire</h4><img src={selectedEvent.venueqrcode} alt="QR Code" /></VenueQRCode>}
                                </DetailQRCodes>
                                {(selectedEvent.venue.position.lat !== 0 && selectedEvent.venue.position.long !== 0) && <DetailMap><GEventMap /></DetailMap>}
                            </DetailMapWrapper>
                        </DetailImageWrapper>
                        <DetailContent>
                            <DetailType>{selectedEvent.type}</DetailType>
                            <DetailTitle>{selectedEvent.title}</DetailTitle>
                            <DetailDate>
                                {(selectedEvent.start && selectedEvent.end) &&
                                    <React.Fragment>
                                        {(dayjs(selectedEvent.end).format('DD/MM/YYYY') === dayjs(selectedEvent.start).format('DD/MM/YYYY')) && <span>{dayjs(selectedEvent.start).format('DD/MM/YYYY')} {dayjs(selectedEvent.start).format('HH:mm')} - {dayjs(selectedEvent.end).format('HH:mm')}</span>}
                                        {(dayjs(selectedEvent.end).format('DD/MM/YYYY') !== dayjs(selectedEvent.start).format('DD/MM/YYYY')) && <span>{dayjs(selectedEvent.start).format('DD/MM/YYYY')} - {dayjs(selectedEvent.end).format('DD/MM/YYYY')}</span>}
                                    </React.Fragment>
                                }
                                {(!selectedEvent.end && selectedEvent.start) && dayjs(selectedEvent.start).format('DD/MM/YYYY HH:mm')}
                            </DetailDate>
                            <DetailVenue>
                                {selectedEvent.venue.name && <DetailVenueName>{selectedEvent.venue.name}</DetailVenueName>}
                                {selectedEvent.venue.address && <DetailVenueAddress>{selectedEvent.venue.address}</DetailVenueAddress>}
                            </DetailVenue>                            
                            {selectedEvent.description && <DetailDescription dangerouslySetInnerHTML={{__html: selectedEvent.description.replace(/<a(.*)href="(.*?)">/g,"").replace(/<\/a>/g,"")}}></DetailDescription>}
                        </DetailContent>                
                    </DetailTop>        
                </Detail>
            )}

            {events.length > 0 ?
                <React.Fragment>
                    <GridHorizontal>
                        {events.map((event,index) => (
                            <Event key={index} onClick={() => eventClicked(event)} data-on="click" data-event-category="Events" data-event-action="open" data-event-label={event.title}>
                                <EventImageWrapper>
                                    {event.imageurl && <EventImage src={event.imageurl} alt={event.title} />}
                                </EventImageWrapper>
                                <EventContent>
                                    <EventType>{event.type}</EventType>
                                    <EventTitle>{event.title}</EventTitle>
                                    <EventVenue>{event.venue.name}</EventVenue>
                                    <EventDate>
                                        {(event.start && event.end) &&
                                        <React.Fragment>
                                            {(dayjs(event.end).format('DD/MM/YYYY') === dayjs(event.start).format('DD/MM/YYYY')) && <span>{dayjs(event.start).format('DD/MM/YYYY')} <EventHour>{dayjs(event.start).format('HH:mm')} - {dayjs(event.end).format('HH:mm')}</EventHour></span>}
                                            {(dayjs(event.end).format('DD/MM/YYYY') !== dayjs(event.start).format('DD/MM/YYYY')) && <span>{dayjs(event.start).format('DD/MM/YYYY')} - {dayjs(event.end).format('DD/MM/YYYY')}</span>}
                                        </React.Fragment>
                                        }
                                        {(!event.end && event.start) && dayjs(event.start).format('DD/MM/YYYY HH:mm')}
                                    </EventDate>
                                </EventContent>

                            </Event>
                        ))}
                        {placeholder}
                    </GridHorizontal>
                    {(swipeIndicator && layout === 3 && events.length > 8) && <SwipeIndicatorWrapper direction="h" />}
                    {(swipeIndicator && layout !== 3 && events.length > 8) && <SwipeIndicatorWrapper direction="v" />}
                </React.Fragment>
            :
                <Placeholder>
                    <PlaceholderBackground src={images.placeholder.agenda_big} alt={"Placeholder"} />
                </Placeholder>
            }

        </HomeWrapper>
    );
}

export default Events;