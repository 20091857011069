import {
    ADS_RECEIVED,
    BIKES_RECEIVED,
    BUS_RECEIVED,
    EREIDER_RECEIVED,
    EVENTS_TODAY_RECEIVED,
    FETCH_ERROR,
    IMXPOI_RECEIVED,
    JOBS_RECEIVED,
    METEO_RECEIVED,
    NEWS_RECEIVED,
    PARKING_RECEIVED,
    POI_HELPERKNAPP_RECEIVED,
    POI_RECEIVED,
    POLL_RECEIVED,
    POLL_START,
    POLL_STOP,
    RADAR_RECEIVED,
    RTLNEWS_RECEIVED, RTLSDG_RECEIVED, RTLWEATHER_RECEIVED, SDG_RECEIVED,
    SET_SELECTED_EVENT,
    SET_SELECTED_IMXPOI,
    SET_SELECTED_POI,
    SET_SELECTED_WHATTODO,
    TRAFIC_RECEIVED,
    TRAINS_RECEIVED,
    VIDEOADS_RECEIVED,
    WHATTODO_RECEIVED
} from "../actions";

    const defaultState = {
        poi: {},
        poi_helperknapp: {}
    };

    const data = (state = defaultState, action) => {
        switch (action.type) {
            case POLL_START:
                return { ...state, polling: true };

            case POLL_STOP:
                return { ...state, polling: true };

            case NEWS_RECEIVED:
                if (JSON.stringify(state.news) !== JSON.stringify(action.json.news)) {
                    return { ...state, news: action.json.news };
                } else {
                    return state;
                }

            case VIDEOADS_RECEIVED:
                if (JSON.stringify(state.videoads) !== JSON.stringify(action.json)) {
                    return { ...state, videoads: action.json };
                } else {
                    return state;
                }

            case METEO_RECEIVED:
                return { ...state, meteo: action.json.TVTMeteoClass.Towns.Town };

            case EVENTS_TODAY_RECEIVED:
                return { ...state, eventsToday: action.json.events };

            case POI_RECEIVED:
                let poiData = {
                    ...state.poi,
                    restaurant: [...action.json.poi.restaurant],
                    bar: [...action.json.poi.bar],
                    night_club: [...action.json.poi.night_club],
                    hospital: [...action.json.poi.hospital],
                    pharmacy: [...action.json.poi.pharmacy],
                    doctor: [...action.json.poi.doctor]
                };

                /*
                poiData.eat_and_drink = Array.from(new Set(poiData.eat_and_drink.map(a => a.id)))
                    .map(id => {
                    return poiData.eat_and_drink.find(a => a.id === id)
                })
                */

                    return {
                    ...state,
                    poi: poiData,
                    poiConfig: action.json.config,
                    loading: false,
                };

            case IMXPOI_RECEIVED:
                let imxallpois = action.json.imx;

                if (imxallpois.error) imxallpois = {};

                let outIMXpoi = {}
                imxallpois.forEach(poi => {
                    poi.categories.forEach(category => {
                        outIMXpoi[category.id] = category.items
                    });

                    outIMXpoi[poi.id] = poi.categories
                });

                return {
                    ...state,
                    imxpoi: outIMXpoi,
                    loading: false
            };

            case WHATTODO_RECEIVED:
                let whatToDoData = action.json.whattodo;

                if (whatToDoData && whatToDoData.error) whatToDoData = {};

                let outWhattodo = {}
                whatToDoData && whatToDoData.forEach(poi => {
                    outWhattodo[poi.id] = poi.items
                })

                    return {
                    ...state,
                    whattodo: outWhattodo,
                    loading: false,
                };

            case POI_HELPERKNAPP_RECEIVED:
                const types = [
                    'manger_boire_dormir',
                    'batiments_communaux',
                    'bornes_voitures_electriques',
                    'sports_et_loisirs',
                    'associations_locales'
                ]

                const type_filter_values = {
                    manger_boire_dormir : 'Manger, Boire & Dormir',
                    batiments_communaux : 'Bâtiments communaux',
                    bornes_voitures_electriques : 'Bornes voitures électriques',
                    sports_et_loisirs : 'Sports et Loisirs',
                    associations_locales : 'Associations locales'
                }

                let poi_helperknapp = {};

                types.forEach(type => {
                    poi_helperknapp[type] = action.json.filter(item => item.type === type_filter_values[type]);

                    // Bring data to default POI form
                    poi_helperknapp[type].forEach(poi => {
                        // Position
                        poi.position = {
                            lat: poi.latitude,
                            lng: poi.longitude
                        }

                        poi.photourl = poi.photo;
                        
                        delete poi.latitude;
                        delete poi.longitude;
                        delete poi.type;
                        delete poi.photo;
                    });                    
                });

                return {
                    ...state,
                    poi_helperknapp: poi_helperknapp,
                    loading: false
            }

            case SET_SELECTED_POI:
                return { ...state, selectedPoi: action.poi };

            case SET_SELECTED_EVENT:
                return { ...state, selectedEvent: action.event };
            
            case SET_SELECTED_IMXPOI:
                return { ...state, selectedimxpoi: action.imxpoi }

            case SET_SELECTED_WHATTODO:
                return { ...state, selectedWhattodo: action.whattodo }

            case EREIDER_RECEIVED:
                if (JSON.stringify(state.ereider) !== JSON.stringify(action.json)) {
                    return { ...state, ereider: action.json };
                } else {
                    return state;
                }

            case TRAFIC_RECEIVED:
                if (JSON.stringify(state.trafic) !== JSON.stringify(action.json)) {
                    return { ...state, trafic: action.json };
                } else {
                    return state;
                }

            case RADAR_RECEIVED:
                if (JSON.stringify(state.radar) !== JSON.stringify(action.json)) {
                    return { ...state, radar: action.json };
                } else {
                    return state;
                }

            case PARKING_RECEIVED:
                return { ...state, poi: { ...state.poi, parking: action.json } };

            case BIKES_RECEIVED:
                return {
                    ...state,
                    poi: { ...state.poi, velok: action.json, veloh: action.json },
                };

            case TRAINS_RECEIVED:
                let data = action.json;
                if (data.error) data = {};
                return { ...state, poi: { ...state.poi, trains: data } };

            case BUS_RECEIVED:
                let busData = action.json;
                if (busData.error) busData = {};
                for (const [key, item] of Object.entries(busData)) {
                    
                    item.key = key;

                    // Position
                    item.position = {
                        lat: item.lat,
                        lng: item.lng
                    }
                    
                    delete item.lat;
                    delete item.lng;
                };

                return { ...state, poi: { ...state.poi, bus: busData } };

            case JOBS_RECEIVED:
                return { ...state, jobs: action.json.jobs };

            case POLL_RECEIVED:
                return { ...state, poll: action.json };

            case SDG_RECEIVED:
                return { ...state, poi: { ...state.poi, sdg: action.json } };

            case ADS_RECEIVED:
                return { ...state, ads: action.json };
            
            case RTLNEWS_RECEIVED:
                return { ...state, rtl_news: action.json.articles };

            case RTLWEATHER_RECEIVED:
                return { ...state, rtl_weather: action.json };

            case RTLSDG_RECEIVED:
                return { ...state, rtl_sdg: action.json };

            case FETCH_ERROR:
                console.log("FETCH ERROR: SHOW ERROR PAGE : " + action.error);
                return state;

            default:
                return state;
        }
    };

    export default data;

    
