import React, {useState} from 'react';
import {Translate} from 'react-localize-redux';
import {useDispatch, useSelector} from 'react-redux';
import styled, {css} from 'styled-components';
import images from '../config/images';
import {
    SET_ACTIONBUTTON,
    SET_SELECTED_IMXPOI,
    SET_SELECTED_POI,
    SET_SELECTED_WHATTODO, SET_SUBNAVITEM
} from '../redux/actions';


const SubNavigationWrapper = styled.div`
    max-width: ${props => props.theme.scale * 320}px; 
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;

    ${(props) =>
        props.theme.navSubBackground
            ? `
            background-color: ${props.theme.navSubBackground};
            `
            : `
            background-color: ${props.theme.shade4};
    `}
`;

const SubNavigationWrapperSimple = styled.div`
    max-width: ${props => props.theme.scale * 320}px; 
    width: 100%;
    z-index: 100;

    ${(props) =>
        props.theme.navSubBackground
            ? `
            background-color: ${props.theme.navSubBackground};
            `
            : `
            background-color: ${props.theme.shade4};
    `}
`;

const Title = styled.div`
    height: ${props => props.theme.scale * 60}px; 
    text-align: center;
    font-size: ${props => props.theme.scale * 21}px; 
    font-family: 'MuseoSans-900';
    padding: ${props => props.theme.scale * 0}px ${props => props.theme.scale * 20}px ${props => props.theme.scale * 0}px ${props => props.theme.scale * 50}px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.theme.navSubForeground
            ? `
            color: ${props.theme.navSubForeground};
            `
            : `
            color: ${props.theme.white};
    `}
`;


const bottomMixin = css`
    bottom: ${props => props.theme.scale * 60}px;
`;

const topMixin = css`
    top: ${props => props.theme.scale * 60}px;
`;


const Dropdown = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    ${props => props.$mr ? bottomMixin : topMixin};
    left: 0;
    width: 100%;
    display: ${props => props.$navstate === 'open' ? 'block' : 'none'};

    ${(props) =>
        props.theme.navSubBackground
            ? `
            background-color: ${props.theme.navSubBackground};
            `
            : `
            background-color: ${props.theme.shade4};
    `}
`;

const DropdownItem = styled.li`
    text-align: center;
    font-size: ${props => props.theme.scale * 18}px; 
    font-family: 'MuseoSans-500';
    padding: ${props => props.theme.scale * 10}px ${props => props.theme.scale * 20}px ${props => props.theme.scale * 10}px ${props => props.theme.scale * 50}px;

    ${(props) =>
        props.theme.navSubForeground
            ? `
            color: ${props.theme.navSubForeground};
            `
            : `
            color: ${props.theme.white};
    `}
`;

const SubNavBox = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;

    ${(props) =>
        props.theme.navSubBackground
            ? `
            background-color: ${props.theme.navSubBackground};
            `
            : `
            background-color: ${props.theme.shade4};
    `}
`;

const SubNavBoxItem = styled.li`
    text-align: center;
    font-size: ${props => props.theme.scale * 18}px; 
    font-family: 'MuseoSans-500';
    padding: ${props => props.theme.scale * 10}px ${props => props.theme.scale * 20}px ${props => props.theme.scale * 10}px ${props => props.theme.scale * 50}px;

    ${props => props.active && `
        font-weight: bold;
        background-color: ${props.theme.shade4};
    `};

    ${(props) =>
        props.theme.navSubForeground
            ? `
            color: ${props.theme.navSubForeground};
            `
            : `
            color: ${props.theme.white};
    `}
`;

const TitleIcon = styled.div`
    position: absolute;
    top: ${props => props.theme.scale * 8}px;
    left: ${props => props.theme.scale * 10}px;
    width: ${props => props.theme.scale * 40}px; 
    height: ${props => props.theme.scale * 40}px; 
    background: url(${images.icons.menu}) no-repeat center center;

    ${(props) =>
        props.theme.navSubForeground
            ? `
            color: ${props.theme.navSubForeground};
            .st3 {
                stroke: ${props.theme.navSubForeground}; 
            }
            `
            : `
            color: ${props => props.theme.white};
            .st3 {
                stroke: ${props.theme.white}; 
            }
    `}
`;

const SubNavigation = ({ className }) => {
    const navItem = useSelector(state => state.config.navItem);
    const subNavItem = useSelector(state => state.config.subNavItem);
    const mobiliteReduite = useSelector(state => state.config.mobiliteReduite);
    const showSubNav = useSelector(state => state.config.theme.showSubNav);
    const [navState, setNavState] = useState('closed');
    const dispatch = useDispatch();


    if (!navItem) return (null);
    if (!subNavItem) return (null);

    // Filter Subnav, remove current selected item
    let subnav = (!showSubNav) ? navItem.subnavigation.filter(item => item.id !== subNavItem.id) : navItem.subnavigation;

    const itemClicked = (item) => { 
        setNavState('closed');
        dispatch({ type: SET_SUBNAVITEM, subNavItem: item});
        dispatch({ type: SET_SELECTED_POI, poi: undefined});
        dispatch({ type: SET_SELECTED_IMXPOI, imxpoi: undefined});
        dispatch({ type: SET_SELECTED_WHATTODO, whattodo: undefined});
        dispatch({ type: SET_ACTIONBUTTON, actionButton: undefined});
    }

    const toggleNav = (e) => { 
        if (navState === 'open') setNavState('closed');
        else setNavState('open');
        e.stopPropagation();
    }

    let Icon = images.icons.menuComponent;

    return (
            <>
            {showSubNav ? 
                <SubNavigationWrapperSimple className={className}>
                    <SubNavBox $mr={mobiliteReduite}>
                        {subnav.map((item) => (
                            <React.Fragment key={item.id}>
                            {item.status !== 0 && 
                                <SubNavBoxItem key={item.id} active={subNavItem.id === item.id} onClick={() => itemClicked(item)} data-on="click" data-event-category="Subnavigation" data-event-action="open" data-event-label={item.name}>
                                    {subNavItem.name ? <span dangerouslySetInnerHTML={{__html: item.name}}></span> : <Translate id={"navigation." + item.id} />}
                                </SubNavBoxItem>
                            }
                            </React.Fragment>
                        ))}
                    </SubNavBox>
                </SubNavigationWrapperSimple>
                : 
                <SubNavigationWrapper className={className}>   
                    <Title onClick={(e) => toggleNav(e)}>
                        <TitleIcon><Icon /></TitleIcon>
                        {subNavItem.name ? <span dangerouslySetInnerHTML={{__html: subNavItem.name}}></span> : <Translate id={"navigation." + subNavItem.id} />}
                    </Title>
                    <Dropdown $navstate={navState} $mr={mobiliteReduite}>
                        {subnav.map((item) => (
                            <React.Fragment key={item.id}>
                            {item.status !== 0 && 
                                <DropdownItem key={item.id} onClick={() => itemClicked(item)} data-on="click" data-event-category="Subnavigation" data-event-action="open" data-event-label={item.name}>
                                    {subNavItem.name ? <span dangerouslySetInnerHTML={{__html: item.name}}></span> : <Translate id={"navigation." + item.id} />}
                                </DropdownItem>
                            }
                            </React.Fragment>
                        ))}
                    </Dropdown>
                </SubNavigationWrapper>
            }
            </>
    );
}

export default SubNavigation;