import React, {useEffect, useRef, useState} from "react";
import {Translate} from "react-localize-redux";
import {useDispatch, useSelector} from "react-redux";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled, {css} from "styled-components";
import {
    Container,
    LeftCol,
    List, Loading,
    RightCol
} from "../components/Layout";
import SubNavigation from "../components/SubNavigation";
import SwipeIndicator from "../components/SwipeIndicator";
import images from '../config/images';
import {SET_ACTIONBUTTON, SET_SWIPEINDICATOR} from "../redux/actions";

const selectedMixin = css`
    font-family: "MuseoSans-900", sans-serif; 
`;

const Error = styled.div`
    font-size: ${(props) => props.theme.scale * 24}px;
    font-family: "MuseoSans-900", sans-serif;
    padding: ${(props) => props.theme.scale * 60}px;
    width: ${(props) => props.theme.scale * 420}px;
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    font-family: "MuseoSans-500", sans-serif;
    flex: 0 0 auto;
    ${(props) => props.theme.layout === 4 && `margin: ${props.theme.scale * 8}px 0 ${props.theme.scale * 8}px ${props.theme.scale * 18}px;`};
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    ${(props) => props.selected && selectedMixin}
`;

const ItemDescription = styled.div`
    box-sizing: border-box;
    padding-left: ${(props) => props.theme.scale * 30}px;
    padding-top: ${(props) => props.theme.scale * 15}px;
    padding-bottom: ${(props) => props.theme.scale * 15}px;
    padding-right: ${(props) => props.theme.scale * 15}px;
`;

const ItemTitle = styled.div`
    font-size: ${(props) => props.theme.scale * 20}px;
    ${(props) =>
        props.theme.textDefault
            && `
            color: ${props.theme.textDefault};
    `}
`;

const Pdf = styled.img`
    max-width: ${(props) => props.theme.scale * 580}px;
    @media only screen and (min-width: 2160px) {
        .container {
          width: 1160px;
        }
      }
`;

const SwiperItem = styled.div`
    .swiper-slide__container {
        width: 100%;
        overflow: hidden;
    }
    &:focus {
        outline: none;
    }
`;

const StyledSlickSlider = styled(SlickSlider)`
    height: ${(props) => props.theme.scale * 880}px;
    position: relative;
    
    max-width: ${(props) => props.theme.documentWidth }px;
    ${(props) => props.theme.layout === 4 && `max-width: ${props.theme.scale * 598}px;`};

    .slick-list {
        height: ${(props) => props.theme.scale * 890}px;
    }

    .slick-track {
        height: ${(props) => props.theme.scale * 890}px;
        width: 100%;
    }

    &:focus {
        outline: none;
    }
    .slick-slide {
        width: 100%;
        height: ${(props) => props.theme.scale * 890}px;
    }
    .slick-prev {
        top: auto;
        bottom: ${(props) => props.theme.scale * 140}px;
        left: ${(props) => props.theme.scale * 360}px;
    }
    .slick-next {
        top: auto;
        bottom: ${(props) => props.theme.scale * 140}px;
        right: ${(props) => props.theme.scale * 360}px;
    }

    ${(props) =>
        props.$mr  &&
        props.theme.layout === 4 &&
        `top: ${props.theme.scale * 500}px;`};
`;

const Pager = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.scale * 20}px;
    font-size: ${(props) => props.theme.scale * 20}px;
    font-family: "MuseoSans-900", sans-serif;
    color: ${(props) => props.theme.black};
    text-align: center;
    position: relative;
    flex: 0 1 50%;

    ${(props) =>
        props.theme.layout === 3 &&
        `
        display: none;
    `}

    ${(props) =>
        props.$mr  &&
        props.theme.layout === 4 &&
        `top: ${props.theme.scale * 460}px;`};
`;

const PagerContainer = styled.div`
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: ${(props) => props.theme.scale * 40}px;

    ${(props) =>
        props.theme.layout === 3 &&
        `
        width: auto;
        left: ${props.theme.scale * 40}px;
    `}
`;

const PageDownload = styled.div`
    width: 100%;
    display: flex;
`;

const PageVisible = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.scale * 20}px;
    font-size: ${(props) => props.theme.scale * 20}px;
    font-family: "MuseoSans-900", sans-serif;
    color: ${(props) => props.theme.highlight};
    text-align: center;
`;

const Page = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.scale * 50}px ${(props) => props.theme.scale * 20}px ${(props) => props.theme.scale * 20}px ${(props) => props.theme.scale * 20}px;
    font-size: ${(props) => props.theme.scale * 20}px;
    font-family: "MuseoSans-900", sans-serif;
    color: ${(props) => props.theme.highlight};
    text-align: center;

    ${(props) =>
        props.theme.layout !== 3 &&
        `
        display: none;
    `}
`;

const Download = styled.div`
    flex: 1 0 50%;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ScrollContainer = styled.div`
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
`;

const PdfContainer = styled.div`
        width: 100%;
`;

const LeftColWrapper = styled(LeftCol)`
    ${props => props.showsubnav ? props.$mr  ?`
            display: grid;
            grid-template-rows: 1fr min-content;
        ` : `
            display: grid;
            grid-template-rows: min-content 1fr;
        ` 
    : `
        max-height: calc(100% - ${props.theme.scale * 60}px);
    `};
`;

const SwipeIndicatorWrapper = styled(SwipeIndicator)`
    position: absolute;
    top: ${(props) =>
        props.theme.scale * (props.theme.scale * 360)}px;
    .container {
        left: ${(props) =>
            props.theme.scale * (props.theme.scale * 598 / 2 - 50)}px;
    }
`;

const DownloadPopupContainer = styled.div`
    position: absolute;
    background: rgba(255,255,255,0.8);
    width: ${(props) => props.theme.scale * 400}px;
    height: ${(props) => props.theme.scale * 580}px;
   
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${props => props.$mr  ? `
        bottom: ${props.theme.scale * 150}px;
        left: ${(((props.theme.contentWidth - 320) / 2) - 200) * props.theme.scale}px;
    ` : `
        top: ${props.theme.scale * 200}px;
        left: ${(((props.theme.contentWidth - 320) / 2) - 200) * props.theme.scale}px;
    `}
`;

const QRCodeImage = styled.img`
    width: 90%,
`;

const QRCodeTitle = styled.div`
    font-size: ${(props) => props.theme.scale * 28}px;
    font-family: "MuseoSans-900", sans-serif;
    text-align: center;
    padding: 0 ${(props) => props.theme.scale * 30}px;

    ${(props) =>
        props.theme.downloadPopupText
            ? `
            color: ${props.theme.downloadPopupText};
            `
            : `
            color: ${props.theme.shade1}; 
    `}
`;

const Close = styled.div`
    cursor: Pointer;
    user-select: none;
    width: ${props => props.theme.scale * 28}px;
    height: ${props => props.theme.scale * 28}px;
    position: absolute;
    top: ${props => props.theme.scale * 28}px;
    right: ${props => props.theme.scale * 28}px;
`;

const DownloadButton = styled.div`
    svg {
        height: 100%;
        
        .needfill {
            fill: ${props => props.theme.white};
            color: ${props => props.theme.shade1};
        }

        .shade1 {
            fill: ${props => props.theme.downloadButton}; 
            
            path {
                fill: ${props => props.theme.downloadButton};
            }
        }

        .white {
            fill: ${props => props.theme.white};

            path {
                fill: ${props => props.theme.white};
            }
        }

        rect {
            stroke: ${props => props.theme.downloadButton}; 
        }

        path {
            fill: ${props => props.theme.downloadButton}; 
        }
    }
    width: ${props => props.theme.scale * 200}px; 
`;

const DownloadPopup = ({qrcode, handleDownload}) => {
    const mobiliteReduite = useSelector(state => state.config.mobiliteReduite);

    return (
        <DownloadPopupContainer $mr={mobiliteReduite}>
            <Close  onClick={handleDownload}><img src={images.icons.close} alt="Close" /></Close>
            <QRCodeImage src={qrcode} alt={"Download"} />
            <QRCodeTitle>                
                <Translate id={"ereider.qrdownload"} />
            </QRCodeTitle>
        </DownloadPopupContainer>
    );
}

const EReiderScroll = ({itemSelected}) => {
    const swipeIndicator = useSelector((state) => state.config.swipeIndicator);
    const theme = useSelector((state) => state.config.theme);
    const [showDownloadPopup, setShowDownloadPopup] = useState(false);
    const dispatch = useDispatch();

    const hideSwiper = () => {
        dispatch({ type: SET_SWIPEINDICATOR, state: false });
    };

    const handleDownload = (e) => {
        setShowDownloadPopup(!showDownloadPopup);
    }

    let downloadButtonUp, downloadButtonDown;
    if (theme.iconSet) {
        downloadButtonDown = <img src={`images/themes/${theme.iconSet}/ic_download_down.svg`} onClick={handleDownload} width="200" alt={"Download"} />;
        downloadButtonUp = <img src={`images/themes/${theme.iconSet}/ic_download_up.svg`} onClick={handleDownload} width="200" alt={"Download"} />;
    } else {
        let IconUp = images.icons.downloadUp;
        let IconDown = images.icons.downloadDown;
        downloadButtonDown = <DownloadButton onClick={handleDownload}><IconUp /></DownloadButton>;
        downloadButtonUp = <DownloadButton onClick={handleDownload}><IconDown /></DownloadButton>;
    }

    return (
        <ScrollContainer onTouchStart={hideSwiper} onMouseDown={hideSwiper}>
            {itemSelected.pages.map((item, i) => (
                <PdfContainer key={i}>
                    <Pdf
                        src={item.url}
                        alt="page"
                    />
                    <PageDownload>
                        {theme.showDownload && <Download>
                            {showDownloadPopup ?
                                downloadButtonDown
                            : 
                                downloadButtonUp
                            } 
                            
                        </Download>}
                        <PageVisible>
                            {i + 1} / {itemSelected.pages.length}
                        </PageVisible>
                    </PageDownload>
                </PdfContainer>
            ))}
            {showDownloadPopup && <DownloadPopup qrcode={itemSelected.qr_download} handleDownload={handleDownload} />}
            {swipeIndicator && <SwipeIndicatorWrapper direction={'v'} />}
        </ScrollContainer>
    );
}

const EReiderSlider = ({ myref, mobiliteReduite, itemSelected, pageSelected, setPageSelected }) => {
    const layout = useSelector((state) => state.config.layout);
    const [showDownloadPopup, setShowDownloadPopup] = useState(false);
    const theme = useSelector((state) => state.config.theme);

    let slidesToShow = 1;
    let slidesToScroll = 1;
    let infinite = true;

    if (layout === 3) {
        infinite = false;
        slidesToShow = 2.2;
        slidesToScroll = 1;
    }

    const params = {
        infinite: infinite,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: slidesToScroll,
        dots: false,
        arrows: false,
        afterChange: (index) => setPageSelected(index + 1),
    };

    const handleDownload = (e) => {
        setShowDownloadPopup(!showDownloadPopup);
    }

    let downloadButtonUp, downloadButtonDown;
    if (theme.iconSet) {
        downloadButtonDown = <img src={`images/themes/${theme.iconSet}/ic_download_down.svg`} onClick={handleDownload} width="200" alt={"Download"} />;
        downloadButtonUp = <img src={`images/themes/${theme.iconSet}/ic_download_up.svg`} onClick={handleDownload} width="200" alt={"Download"} />;
    } else {
        let IconUp = images.icons.downloadUp;
        let IconDown = images.icons.downloadDown;
        downloadButtonDown = <DownloadButton onClick={handleDownload}><IconUp /></DownloadButton>;
        downloadButtonUp = <DownloadButton onClick={handleDownload}><IconDown /></DownloadButton>;
    }
    
    return (
        <>
            <StyledSlickSlider
                ref={myref}
                mr={mobiliteReduite}
                {...params}
            >
                {itemSelected.pages.map((item, i) => (
                    <SwiperItem key={i}>
                        <div className="swiper-slide__container">
                            <Pdf
                                src={item.url}
                                alt="page"
                            />
                            <Page>
                                {i + 1} / {itemSelected.pages.length}
                            </Page>
                        </div>
                    </SwiperItem>
                ))}
            </StyledSlickSlider>
            

            <PagerContainer>
                {theme.showDownload && <Download>
                    {showDownloadPopup ?
                        downloadButtonDown
                    : 
                        downloadButtonUp
                    } 
                    
                </Download>}
                <Pager $mr={mobiliteReduite}>
                    {pageSelected} / {itemSelected.pages.length}
                </Pager>
            </PagerContainer>
            {showDownloadPopup && <DownloadPopup qrcode={itemSelected.qr_download} handleDownload={handleDownload} />}
        </>
    )
};

const EReider = () => {
    const [itemSelected, setItemSelected] = useState(undefined);
    const [pageSelected, setPageSelected] = useState(1);
    const layout = useSelector((state) => state.config.layout);
    const mobiliteReduite = useSelector(
        (state) => state.config.mobiliteReduite
    );
    const ereider = useSelector((state) => state.data.ereider);
    const theme = useSelector((state) => state.config.theme);
    const subNavItem = useSelector((state) => state.config.subNavItem);
    const dispatch = useDispatch();
    const showSubNav = useSelector(state => state.config.theme.showSubNav);

    const mySlider = useRef(undefined);
    const [selectedNav, setSelectedNav] = useState(undefined);
    let output = null;


    useEffect(() => {

        if (ereider && ereider.length > 0) {
            setSelectedNav(ereider[0].id);
            if (subNavItem) setSelectedNav(subNavItem.id);
        }

        if (subNavItem && ereider) {

            setPageSelected(1);
            if (mySlider.current) mySlider.current.slickGoTo(0);
            let ereiderCat = ereider.find((item) => item.id === subNavItem.id);
            if (ereiderCat) setItemSelected(ereiderCat.documents[0]);
            dispatch({
                type: SET_ACTIONBUTTON,
                actionButton: "mail",
                action: ereider[0].pdfurl,
            });
            //dispatch({ type: SET_ACTIONBUTTON, actionButton: undefined});
        }
    }, [subNavItem, ereider, dispatch, selectedNav]);

    if (!ereider) return null;

    const itemClicked = (item) => {
        setPageSelected(1);
        if (mySlider.current) mySlider.current.slickGoTo(0);
        setItemSelected(item);
        dispatch({
            type: SET_ACTIONBUTTON,
            actionButton: "mail",
            action: item.pdfurl,
        });
    };

    if (!itemSelected && ereider && ereider.documents) {
        if (ereider[0].documents.length > 0) {
            setItemSelected(ereider[0].documents[0]);
            dispatch({
                type: SET_ACTIONBUTTON,
                actionButton: "mail",
                action: ereider[0].documents[0].pdfurl,
            });
        }
    }

    

    if (selectedNav) {
        let ereiderCat = ereider.find((item) => item.id === selectedNav);

        if (!ereiderCat) return null;

        if (itemSelected) {
            output = ereiderCat.documents.map((item, i) => (
                <Item
                    key={i}
                    onClick={() => itemClicked(item)}
                    selected={itemSelected.id === item.id}
                    data-on="click"
                    data-event-category="EReider"
                    data-event-action="open"
                    data-event-label={item.title}
                >
                    <ItemDescription>
                        <ItemTitle
                            dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                    </ItemDescription>
                </Item>
            ));
        }
    }

    let sliderOutput = <Loading />;

    if (itemSelected) {
        switch (layout) {
            case 4:
                sliderOutput = <EReiderScroll itemSelected={itemSelected} />;
                break;
            default:
                sliderOutput = <EReiderSlider 
                    myref={mySlider} 
                    itemSelected={itemSelected} 
                    pageSelected={pageSelected} 
                    mobiliteReduite={mobiliteReduite} 
                    setPageSelected={setPageSelected}
                />;
        }
       
    }

    return (
        <Container color={theme.lightgray}>
            <LeftColWrapper color={theme.lightgray} width={320} showsubnav={showSubNav} $mr={mobiliteReduite}>
                {!mobiliteReduite && <SubNavigation />}
                {selectedNav && (
                    <List $mr={mobiliteReduite}>
                        {output}
                    </List>
                )}
                {mobiliteReduite && <SubNavigation />}
            </LeftColWrapper>
            <RightCol color={theme.white}>
                {selectedNav && itemSelected ? (
                    <React.Fragment>
                        {sliderOutput}
                    </React.Fragment>
                ) : (
                    <Error>
                        <Translate id={"ereider.error"} />
                    </Error>
                )}
            </RightCol>
        </Container>
    );
};

export default EReider;
