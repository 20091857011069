import {
    GoogleMap,
    LoadScriptNext,
    Marker,
    MarkerClusterer
} from "@react-google-maps/api";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import images from "../config/images";
import {centerControl} from "../config/mapHelper";
import mapStyles from "../config/mapStyles";
import {SET_SELECTED_IMXPOI, SET_SELECTED_POI, SET_SELECTED_WHATTODO} from "../redux/actions";

const GoogleMapWrapper = styled.div`
    width: 100%;
    height: 100%;
    a[href^="http://maps.google.com/maps"],
    a[href^="https://maps.google.com/maps"],
    a[href^="https://www.google.com/maps"]
    {
        display: none !important;
    }
    .gm-style-cc {
        display: none !important;
    }

    .gm-style a[href^="https://maps.google.com/maps"]
    {
        display: none !important;
    }
`;

const getGoogleClusterInlineSvg = (color) => {
    var encoded = window.btoa(
        '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 51 51"><defs><clipPath id="clip-path" transform="translate(-1 -1)"><rect width="53" height="53" fill="none"/></clipPath><clipPath id="clip-path-2" transform="translate(-1 -1)"><rect x="3.19" y="3.19" width="46.63" height="46.62" fill="none"/></clipPath></defs><g opacity="0.35"><g clip-path="url(#clip-path)"><path d="M26.5,52A25.5,25.5,0,1,0,1,26.5,25.5,25.5,0,0,0,26.5,52" transform="translate(-1 -1)" fill="' +
            color +
            '"/></g></g><g opacity="0.7"><g clip-path="url(#clip-path-2)"><path d="M26.5,48.81A22.31,22.31,0,1,0,4.19,26.5,22.31,22.31,0,0,0,26.5,48.81" transform="translate(-1 -1)" fill="' +
            color +
            '"/></g></g><path d="M26.5,45.3A18.8,18.8,0,1,0,7.69,26.5,18.8,18.8,0,0,0,26.5,45.3" transform="translate(-1 -1)" fill="' +
            color +
            '"/></svg>'
    );

    return "data:image/svg+xml;base64," + encoded;
};

const getDefaultMarker = (color) => {
    var encoded = window.btoa(
        '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 48.72"><title>ic_marker</title><path d="M20,48.72a6.13,6.13,0,0,1-4.54-2C9.68,40.33,0,28.36,0,19.74,0,7.39,10.17,0,20,0S40,7.39,40,19.74c0,8.62-9.68,20.59-15.46,27a6.14,6.14,0,0,1-4.54,2" style="fill:' +
            color +
            '"/><path d="M20,27.2A7.2,7.2,0,1,1,27.21,20,7.22,7.22,0,0,1,20,27.2" style="fill:#fff"/></svg>'
    );

    return "data:image/svg+xml;base64," + encoded;
};

const getDefaultBorneMarker = (color) => {
    var encoded = window.btoa(
        '<?xml version="1.0" encoding="UTF-8"?><svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M15,30c-3.84,0-7.68-1.46-10.61-4.39C1.56,22.78,0,19.01,0,15S1.56,7.23,4.4,4.39C7.23,1.56,11,0,15,0s7.77,1.56,10.61,4.39c5.85,5.85,5.85,15.37,0,21.22-2.92,2.92-6.77,4.39-10.61,4.39" style="fill:' +
            color +
            '"/><path d="M15.3,9.62h-.59c-.46,0-.89,.26-1.09,.68l-.64,1.29c-.18,.36-.52,.61-.92,.67l-1.43,.21c-.46,.07-.84,.39-.99,.83l-.18,.56c-.14,.44-.03,.93,.31,1.25l1.03,1.01c.29,.28,.42,.68,.35,1.08l-.24,1.42c-.08,.46,.11,.92,.49,1.19l.48,.35c.21,.15,.46,.23,.72,.23,.19,0,.39-.05,.57-.14l1.28-.67c.18-.09,.37-.14,.57-.14s.39,.05,.57,.14l1.28,.67c.18,.09,.37,.14,.57,.14,.25,0,.51-.08,.72-.23l.48-.35c.38-.27,.56-.73,.49-1.19l-.24-1.42c-.07-.4,.06-.8,.35-1.08l1.03-1.01c.33-.32,.45-.81,.31-1.25l-.18-.56c-.14-.44-.53-.76-.98-.83l-1.43-.21c-.4-.06-.74-.31-.92-.67l-.64-1.29c-.21-.42-.63-.68-1.1-.68" style="fill:#fff;"/></svg>'
    );

    return "data:image/svg+xml;base64," + encoded;
};

const GMap = ({type, poitype}) => {
    const poi = useSelector((state) => state.data.poi);
    const poi_helperknapp = useSelector((state) => state.data.poi_helperknapp);
    const poi_imx = useSelector((state) => state.data.imxpoi);
    const poi_whattodo = useSelector((state) => state.data.whattodo);
    const poiConfig = useSelector((state) => state.data.poiConfig);
    const locationConfig = useSelector((state) => state.config.location);
    const selectedDefaultPoi = useSelector((state) => state.data.selectedPoi);
    const selectedIMXPoi = useSelector(state => state.data.selectedimxpoi);
    const selectedWhattodo = useSelector(state => state.data.selectedWhattodo);
    const theme = useSelector((state) => state.config.theme);
    const layout = useSelector((state) => state.config.layout);
    const mobiliteReduite = useSelector(
        (state) => state.config.mobiliteReduite
    );
    const dispatch = useDispatch();

    // HACK - We need to change this
    let currentPoi = poi;
    if (poitype === "helperknapp") currentPoi = poi_helperknapp;
    if (poitype === "imxpoi") currentPoi = poi_imx;
    if (poitype === "whattodo") currentPoi = poi_whattodo;

    // Set Default Zoom
    // let zoom = 14;
    let zoom = 16;

    if (!poiConfig) return null;

    const style = {
        width: "100%",
        height: "100%",
    };

    let center, home, selectedPoi;

    if (poitype === 'imxpoi') selectedPoi = selectedIMXPoi; else if (poitype === 'whattodo') selectedPoi = selectedWhattodo; else selectedPoi = selectedDefaultPoi;

    if (selectedPoi) {
        center = selectedPoi.position;
        zoom = 18;
    }  else if (selectedIMXPoi) {
        center = selectedIMXPoi.position;
        zoom = 18;
    }  else if (selectedWhattodo){
        center = selectedWhattodo.position;
        zoom = 18;
    }  else {
        center = home = {
            lat: locationConfig.latitude,
            lng: locationConfig.longitude,
        };
    }

    const markerClicked = (poi) => {
        if (poitype === 'imxpoi') dispatch({type: SET_SELECTED_IMXPOI, imxpoi: poi}); else if (poitype === 'whattodo') dispatch({type: SET_SELECTED_WHATTODO, whattodo: poi}); else dispatch({type: SET_SELECTED_POI, poi: poi});
    };

    const mapOnLoad = (map, position) => {
        const centerControlDiv = document.createElement("div");
        let useTheme;
        if (theme.iconSet && !theme.useDefaultMapControlIcons) {
            useTheme = theme.iconSet;
        }
        centerControl(
            centerControlDiv,
            map,
            center,
            useTheme,
            theme,
            mobiliteReduite,
            layout
        );

        map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
            centerControlDiv
        );
    };

    let markerColor = theme.shade1;
    if (theme.poiMarker) markerColor = theme.poiMarker;

    let borneMarkerColor = "#f29137";
    if (theme.borneMarker) borneMarkerColor = theme.borneMarker;

    let iconHome,
        iconHomeSize,
        iconPoi,
        iconPoiSize,
        iconPoiSelected,
        iconCluster,
        iconClusterColor;

    if (!theme.iconSet || theme.useDefaultMapIcons) {
        iconHome = getDefaultBorneMarker(borneMarkerColor);
        iconHomeSize = {width: 25, height: 25};

        iconPoi = getDefaultMarker(markerColor);
        iconPoiSize = {width: 20, height: 25};
        iconPoiSelected = images.icons.markerSelected;

        iconCluster = getGoogleClusterInlineSvg(markerColor);
        iconClusterColor = "#FFFFFF";
    } else {
        iconHome = `images/themes/${theme.iconSet}/icpin_default.svg`;
        iconHomeSize = {width: 38, height: 42};

        switch (type) {
            case "trains":
                iconPoi = `images/themes/${theme.iconSet}/icpin_train.svg`;
                iconPoiSize = {width: 38, height: 42};
                iconPoiSelected = iconPoi;
                break;
            case "bus":
                iconPoi = `images/themes/${theme.iconSet}/icpin_bus.svg`;
                iconPoiSize = {width: 38, height: 42};
                iconPoiSelected = iconPoi;
                break;
            case "pharmacy":
            case "hospital":
            case "doctor":
                iconPoi = `images/themes/${theme.iconSet}/icpin_sante.svg`;
                iconPoiSize = {width: 38, height: 42};
                iconPoiSelected = iconPoi;
                break;
            default:
                iconPoi = `images/themes/${theme.iconSet}/icpin_manger_boire.svg`;
                iconPoiSize = {width: 38, height: 42};
                iconPoiSelected = iconPoi;
        }

        iconCluster = `images/themes/${theme.iconSet}/icpin_cluster.svg`;
        iconClusterColor = "#000000";
    }

    const homeMarker = (
        <Marker
            onLoad={(marker) => {
                //console.log('marker: ', marker)
            }}
            position={home}
            icon={{
                url: iconHome,
                scaledSize: iconHomeSize,
            }}
        />
    );

    let output;

    switch (type) {
        case "trains":
            const keys = Object.keys(currentPoi[type]);
            output = keys.map((key, i) => (
                <Marker
                    position={currentPoi[type][key][0].position}
                    key={i}
                    icon={{
                        url:
                            selectedPoi &&
                            currentPoi[type][key].position ===
                                selectedPoi.position
                                ? iconPoiSelected
                                : iconPoi,
                        scaledSize: iconPoiSize,
                    }}
                />
            ));
            break;

        case "bus":
            const busKeys = Object.keys(currentPoi[type]);

            output = busKeys.map((key, i) => {
                return (
                    <Marker
                        position={currentPoi[type][key].position}
                        onClick={() => markerClicked(currentPoi[type][key])}
                        key={i}
                        icon={{
                            url:
                                selectedPoi &&
                                currentPoi[type][key].position ===
                                    selectedPoi.position
                                    ? iconPoiSelected
                                    : iconPoi,
                            scaledSize: iconPoiSize,
                        }}
                    />
                );
            });
            break;
        case "parking":
            output = currentPoi[type].map((item, i) => (
                <Marker
                    key={i}
                    position={item.position}
                    icon={{
                        url: getDefaultMarker(markerColor),
                        scaledSize: {width: 20, height: 25},
                    }}
                />
            ));
            break;

        default:
            output = (
                <MarkerClusterer
                    maxZoom={17}
                    options={{
                        styles: [
                            {
                                width: 40,
                                height: 40,
                                url: iconCluster,
                                textSize: 20,
                                textColor: iconClusterColor,
                            },
                        ],
                    }}
                >
                    {(clusterer) =>
                        currentPoi[type].map((poi, i) => (
                            <Marker
                                onLoad={(marker) => {
                                    //console.log('marker: ', marker)
                                }}
                                onClick={() => markerClicked(poi)}
                                key={i}
                                position={poi.position}
                                icon={{
                                    url:
                                        selectedPoi &&
                                        poi.position === selectedPoi.position
                                            ? iconPoiSelected
                                            : iconPoi,
                                    scaledSize: iconPoiSize,
                                }}
                                clusterer={clusterer}
                            />
                        ))
                    }
                </MarkerClusterer>
            );
    }

    return (
        <LoadScriptNext
            id="script-loader"
            googleMapsApiKey="AIzaSyC0KS2RkGwP6i9-vgSeiEwCmZ-p2Bq2rSQ"
        >
            <GoogleMapWrapper>
                <GoogleMap
                    id="map"
                    center={center}
                    zoom={zoom}
                    mapContainerStyle={style}
                    onLoad={mapOnLoad}
                    options={{
                        disableDefaultUI: true,
                        styles: mapStyles,
                        minZoom: 13,
                        maxZoom: 0,
                        zoomControl: false,
                    }}
                >
                    {homeMarker}
                    {output}
                </GoogleMap>
            </GoogleMapWrapper>
        </LoadScriptNext>
    );
};

export default GMap;
